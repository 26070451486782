module.exports = [{
      plugin: require('/opt/build/repo/gatsby/packages/group/node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"https://wp.staging.ovologroup.com","protocol":"https","wrapperStyle":"","withWebp":false,"maxWidth":1200,"quality":100},
    },{
      plugin: require('/opt/build/repo/gatsby/packages/group/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"NONE","includeInDevelopment":false},
    },{
      plugin: require('/opt/build/repo/gatsby/packages/group/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
